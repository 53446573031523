import { shallowRef, reactive } from 'vue';
import {
    SetUp,
    Setting,
    Avatar,
} from "@element-plus/icons-vue";
import { isAdmin } from '@/util/EnvUtil';
const store = {

    adminData: reactive<any>({
        menus: []
    }),

    iconObj: {
        Setting,
        Avatar,
        SetUp,
    } as any,

    setMenusAction(proxy: any) {
        if (isAdmin()) {
            this.adminData.menus = [{
                "id": 1,
                "label": "用户管理",
                "icon": "Avatar",
                "children": [{
                    "id": 1,
                    "label": "用户列表",
                    "path": "/users",
                }]
            }, {
                "id": 2,
                "label": "页面配置",
                "icon": "Setting",
                "children": [{
                    "id": 2,
                    "label": "基础配置",
                    "path": "/config",
                }]
            }]
        } else {
            this.adminData.menus = [{
                "id": 1,
                "label": "用户管理",
                "icon": "Avatar",
                "children": [{
                    "id": 1,
                    "label": "用户列表",
                    "path": "/users",
                }]
            }]
        }
    },
}

export default store