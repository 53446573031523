import { ref, getCurrentInstance, onMounted } from "vue";
import { SetUp, Setting, Avatar, ArrowRightBold, ArrowLeftBold } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import store from "../store/AdminStore";
export default {
  name: "Admin",
  components: {
    SetUp: SetUp,
    Setting: Setting,
    Avatar: Avatar,
    ArrowRightBold: ArrowRightBold,
    ArrowLeftBold: ArrowLeftBold
  },
  setup: function setup() {
    var router = useRouter();
    var internalInstance = getCurrentInstance();

    var _getCurrentInstance = getCurrentInstance(),
        ctx = _getCurrentInstance.ctx,
        proxy = _getCurrentInstance.proxy;

    console.log(ctx.$api, proxy.$api, internalInstance.appContext.config.globalProperties.$api);
    var isCollapse = ref(false);
    onMounted(function () {
      store.setMenusAction(proxy);
    });

    var handleBreakHome = function handleBreakHome() {
      router.push("/");
    };

    var handleOpen = function handleOpen() {};

    var handleClose = function handleClose() {};

    return {
      adminData: store.adminData,
      isCollapse: isCollapse,
      handleOpen: handleOpen,
      handleClose: handleClose,
      handleBreakHome: handleBreakHome
    };
  }
};